<template>
  <div>
    <template
      v-for="(rate, idx) in rates"
      >
      <Rate
        :class="idx + 1 == rates.length ? '' : 'mb-3'"
        :rate="rate"
        />
    </template>
  </div>
</template>

<script>
const Rate = () => import(/* webpackPrefetch: true */ "@/components/rates/Rate");

export default {
  props: {
    rates: {
      required: true,
      type: Array
    }
  },

  components: { Rate }
}
</script>
